.medal-table-block {
  background: linear-gradient(0deg,
  rgba(250, 237, 125, 0.04) 0%,
  rgba(118, 65, 190, 0.08) 45.83%);
  border: 1px solid #f6f7fa;
  border-radius: 10px;

  .block-body {
    align-items: center;
    color: #292929;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 2.5rem 4rem;

    .image-wrapper {
      margin-bottom: 1.25rem;

      img {
        height: 120px;
      }
    }

    .medalist-table {
      color: #464646;
      font-weight: 700;
      margin-bottom: 1.25rem;
      width: 256px;

      .table-row {
        align-items: center;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        line-height: 16px;
        padding: 0.75rem 2rem;

        &:not(:last-child) {
          border-bottom: 2px solid #ececec;
        }

        .col-user-avatar {
          height: 28px;
          margin-right: 0.5rem;
          position: relative;
          width: 28px;
        }

        .display-name {
          text-transform: capitalize;
        }

        .col-place {
          position: relative;

          span:first-child {
            color: #3c3c3c;
            font-size: 24px;
            font-weight: 900;
          }

          span:last-child {
            font-size: 8px;
            font-weight: 800;
            margin-left: 2px;
            position: absolute;
            text-transform: uppercase;
            top: -4px;
          }
        }
      }

      .table-row-1 {
        font-size: 20px;
        line-height: 26px;
        padding: 0.5rem;

        .col-user-avatar {
          height: 43px;
          margin-right: 1rem;
          position: relative;
          width: 43px;
        }

        .display-name {
          font-size: 24px;
          font-weight: 900;
        }
      }

      .table-row-2 {
        .display-name {
          font-size: 18px;
          font-weight: 800;
        }
      }

      .table-row-3 {
        .display-name {
          font-size: 15px;
          font-weight: 900;
        }
      }

      img {
        border-radius: 50%;
      }
    }
  }
}
