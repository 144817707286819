.lobby-banner-main {
  background-color: #f6f7fa;
  border-bottom: 2px solid #5d34a6;
  border-left: 20px solid #5d34a6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  padding: 16px 8px 8px 16px;
  position: relative;
  z-index: 9;

  &.all-rounded {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .banner-subtitle {
    color: #464646;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    @media (min-width: 992px) {
      -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
      display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
      font-size: 1rem;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .banner-title {
    color: #040404;
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 0;
  }

  .banner-image-box {
    align-items: center;
    display: flex;
    height: 10rem;
    justify-content: center;
    position: absolute;
    right: -2rem;
    top: 50%;
    transform: translateY(-50%);
    width: 10rem;
    z-index: -1;

    .gray-circle {
      border: 2rem solid #ebebeb;
      border-radius: 50%;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .yellow-trophy {
      height: 2.5rem;
      position: relative;
      width: 2rem;
    }
  }

  .action-buttons {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;

    // each button
    & > div { // stylelint-disable-line
      align-items: center;
      border-radius: 20px;
      cursor: pointer;
      display: inline-flex;
      height: 24px;
      margin: 2px 5px;
      min-width: 128px;
      padding: 4px 6px;
      position: relative;
      width: unset;
    }

    .inner-text { // stylelint-disable-line
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      margin: 0 2px;
      overflow: hidden;
      text-transform: capitalize;
      white-space: nowrap;
    }

    .inner-icon { // stylelint-disable-line
      display: inline-flex;
      height: 14px;
      margin: 0 2px;
      width: 14px;
    }
  }

  // "Outing Link" share
  // .btn-outing-link {}

  // "Video Call Link" share
  .btn-vcall-link {
    &:not(.host).not-set {
      background: radial-gradient(50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%),
        #c1c1c1;
      pointer-events: none;
    }

    &.host.not-set {
      .btn-alert {
        display: flex;
      }
    }
  }

  .btn-alert {
    align-items: center;
    background: #f00;
    border-radius: 50%;
    box-shadow: 0 0 3px #f00;
    color: #fff;
    display: none;
    font-size: 10px;
    height: 14px;
    justify-content: center;
    left: -6px;
    line-height: 14px;
    position: absolute;
    top: 5px;
    user-select: none;
    width: 14px;
  }

  // "N Players Connected"
  .btn-players-count {
    // background-color: #5d34a6;
    background: linear-gradient(0deg, #5d34a6, #5d34a6), #fff;

    @media (max-width: 500px) {
      pointer-events: none;
    }
  }
}

.lobby-banner-video-link-not-set-content {
  background: #f00;
  border-radius: 5.03107px;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  padding: 5px 14px;
  // position: absolute;
  // top: -42px;
  // left: -12px;
  width: 160px !important;

  // &::before {
  //     content: "";
  //     position: absolute;
  //     left: 7px;
  //     bottom: -6px;
  //     border-top-style: none;
  //     border-right: 6px solid transparent;
  //     border-left: 6px solid transparent;
  //     border-top: 6px solid #FF0000;
  // }
}

.players-list-container-content {
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px 10px 20px 20px;
  width: 211px;
  z-index: 100;

  .popup-arrow {
    left: -35px;
    width: 100%;
  }

  &::before {
    border-left: 6px solid #5d34a6;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 100;
  }

  .close-icon {
    cursor: pointer;
    display: flex;
  }

  .connected-users {
    border-bottom: 1px solid #000;
    font-size: 12px;
    font-weight: 600;

    .count {
      color: #b7b7b7;
    }
  }

  .players-list {
    max-height: 490px;
    overflow-y: auto;
  }

  .player-row {
    margin: 10px 0;
    min-height: fit-content;

    img {
      border-radius: 50%;
    }

    span.user-name {
      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
