.outing-manual-control {
  align-items: center;
  display: flex;
  height: 20px;
  margin-left: 8px;

  span {
    cursor: pointer;
    display: inline-flex;
    margin-right: 4px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
