.zapp-modal-content {
  background-color: transparent;
  border-style: none;
  max-width: 35rem; // 400px
  padding: 1rem;
  width: 100%;

  @media (min-width: 992px) {
    max-width: 40rem; // 640px
  }
}

.zapp-modal-inner {
  border-radius: 12px;
  overflow: hidden;
}

.zapp-modal-header {
  background-color: #fff;
  border-bottom: 1.5px solid #ececec;
  padding: 0.5rem 0.75rem;

  .container {
    max-width: unset;
  }

  p {
    color: #464646;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
  }

  span.close-button {
    cursor: pointer;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

.zapp-modal-body {
  background-color: #f8f8f8;
  background-image: url('/images/get_started_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding: 1.5rem 2rem;
  position: relative;
  width: 100%;
}

.zapp-modal-body .message-wrapper {
  align-items: center;
  color: #040404;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  line-height: 24px;
  margin: 0 auto;
  max-width: 480px;
}

.zapp-modal-body .message-wrapper p {
  margin-bottom: 0;
  text-align: center;
}

.zapp-modal-body  .actions-wrapper {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
}

.zapp-modal-body  .actions-wrapper .btn {
  background-color: #207ac4;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  line-height: 24px;
  outline: none;
  padding: 0 32px;

  &:hover,
  &:active {
    background-color: #0063b4;
  }

  @media (max-width: 576px) {
    padding: 0 14px;
  }
}
