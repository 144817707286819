.page-home {
  background: #fff;
}
/* landing */
.loading_container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.head_line {
  align-items: center;
  background: #2c8cf4;
  display: flex;
  height: 33.96px;
  justify-content: center;
}

.head_line_text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
  text-decoration-line: underline;
}

.head_line_btn {
  align-items: center;
  background: #fff;
  border-radius: 4.31746px;
  color: #0354ab;
  cursor: pointer;
  display: flex;
  font-size: 13.7949px;
  font-style: normal;
  font-weight: 600;
  height: 20.36px;
  justify-content: center;
  letter-spacing: 1.27226px;
  line-height: 16px;
  margin-left: 20px;
  width: 85.53px;
}

.join_outing_container {
  z-index: 9;
}

.join_outing {
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  height: 150.93px;
  justify-content: center;
  margin-bottom: 40px;
  min-height: 120px;
  padding-left: 40px;

  &_title {
    margin: 0 0 0.5rem;
  }

  &_subtitle {
    margin-bottom: 8.5px;
  }

  &_form {
    display: flex;
    width: 100%;

    &_input {
      flex: 1;
    }

    &_submit {
      border-style: none;
      flex: 0 0 96px;
      margin-left: 5px;
      margin-right: 24px;
    }
  }
}

.get_started {
  background-image: url('/images/get_started_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid #ececec;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 35px;
  padding-bottom: 35px;
  padding-left: 40px;
  padding-top: 35px;
  z-index: 9;
}

.create-outing {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.left_bordered {
  overflow: hidden;
  position: relative;

  &::before {
    background: #5d34a6;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 14px;
    z-index: 1000;
  }
}

.create-event,
.invite-member,
.edit-event,
.user-setting {
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.25);
  margin: 40px auto;
  max-width: 1082px;
  padding: 35px 32px;
  position: relative;

  @media (max-width: 1022px) {
    max-width: 576px;
  }

  @media (max-width: 500px) {
    padding: 32px 16px;
    width: calc(100% - 16px);
  }

  .row {
    justify-content: center;
  }

  .col {
    display: flex;
    flex-direction: column;

    &:first-of-type {
      max-width: 471px;
      padding-right: 26px;
      width: 100%;

      @media (max-width: 400px) {
        padding-right: 12px;
      }
    }

    &:last-of-type {
      max-width: 512px;
    }
  }
}

.invite-member,
.edit-event {
  .games_select {
    border-bottom-width: 1px;
    border-radius: 10px;
    margin-bottom: 40px;
  }
}

.game_detail,
.event-info-box,
.edit_event {
  height: 100%;
  z-index: 9;
}

.get_started_title {
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
}

.get_started_desc {
  margin: 14px 0 16px;
  max-width: 410px;
}

.get_started_btn {
  align-items: center;
  background: #fff;
  border-radius: 6.32718px;
  color: #0354ab;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 1.575px;
  line-height: 19px;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
}

.get_started_choose {
  max-width: 367px;
  text-transform: capitalize;
}
/* end landing */

/* game select */
.create_game_collapsed {
  margin: 20px 0 10px;
}

.create_game_expanded {
  border: 1.25777px solid #ececec;
  border-bottom: unset;
  border-radius: 10px 10px 0 0;
  padding: 10px 20px;
}

.games_select_root {
  z-index: 9;
}

.games_select {
  border: 1px solid #ececec;
  border-bottom-width: 0;
  margin-bottom: 0;
  padding-bottom: 15px;

  .create-event & {
    border-bottom-style: none;
  }
}

.games_select_header {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding: 0 20px;
}

.games_select_header_action {
  align-items: center;
  color: #818181;
  display: flex;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  line-height: 12px;
  text-align: right;
  text-decoration-line: underline;
}

.games_select_body {
  border-radius: 0 0 4.94787px 4.94787px;
  display: flex;
  margin: 0 17px;
  overflow-x: auto;
  user-select: none;

  &::-webkit-scrollbar {
    height: 8px;
    width: calc(100% - 20px);
  }
}

.games_select_container {
  border: 2px solid transparent;
  border-radius: 28px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 15px;
  position: relative;
  width: fit-content;

  &:not(:first-child) {
    margin-left: 16px;
  }

  &.selected {
    border-color: #f00;
  }

  &.disabled {
    &::after {
      align-items: center;
      background-color: #5d34a6;
      border-radius: 2px;
      color: #fff;
      content: 'coming soon';
      display: flex;
      font-size: 5.68945px;
      font-weight: 900;
      height: 20px;
      justify-content: center;
      letter-spacing: 0.659976px;
      line-height: 7px;
      position: absolute;
      right: -7px;
      text-align: center;
      text-transform: uppercase;
      top: 2px;
      width: 40px;
    }
  }

  &.new-game {
    &::after {
      background-color: #f00;
      border-radius: 2px;
      color: #fff;
      content: 'new';
      font-size: 6px;
      font-weight: 900;
      letter-spacing: 0.659976px;
      line-height: 7px;
      padding: 2px;
      position: absolute;
      right: -7px;
      text-align: center;
      text-transform: uppercase;
      top: 2px;
      width: 35px;
    }
  }

  .games_select_img {
    height: 72px;
    user-select: none;
    width: 68px;
  }
}

.disabled-mask {
  background-color: #4a4343;
  border-radius: 28.0884px;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
}

.games_select_status {
  align-items: center;
  color: #1b2733;
  display: flex;
  font-size: 5.77251px;
  font-style: normal;
  font-weight: 900;
  justify-content: center;
  line-height: 138.18%;
  margin-top: 2px;
  text-align: center;
  text-decoration-line: underline;
}
/* end game select */

/* game detail */
.game_detail {
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
}

.game_detail_header {
  align-items: center;
  border-bottom: 1px solid #ececec;
  display: flex;
  height: 25.56px;
  justify-content: space-between;
  padding: 0 20px;
}

.game_detail_header_prev,
.game_detail_header_next {
  color: #464646;
  cursor: pointer;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 11px;

  &.disabled {
    cursor: not-allowed;
  }
}

.game_detail_name {
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  line-height: 7px;
  text-align: center;
}

.game_detail_body {
  border-bottom: 1px solid #ececec;
  display: flex;
  padding: 25px 28px 25px 37px;
}

.game_detail_footer {
  display: grid;
  gap: 16px 10px;
  grid-template-areas:
    'event-footer-title event-footer-queue'
    'event-footer-time event-footer-schedule';
  grid-template-columns: 268px 188px;

  .event-title {
    grid-area: event-footer-title;
  }

  .event-time {
    grid-area: event-footer-time;
  }

  .event-schedule {
    grid-area: event-footer-schedule;
  }

  .event-queue {
    grid-area: event-footer-queue;

    .event-queue-game > div {
      margin: 0 2px 0 0;
    }
  }

  @media (max-width: 500px) {
    grid-template-areas:
      'event-footer-title'
      'event-footer-time'
      'event-footer-queue'
      'event-footer-schedule';
    grid-template-columns: 100%;
  }
}

.game_detail_footer {
  padding: 20px 7px 28px 17px;

  .event-title {
    display: flex;
    flex-direction: column;

    input {
      border: 0.904196px solid #cdcdcd;
      border-radius: 26px;
      color: #636363;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      height: 27px;
      line-height: 16px;
      outline: none;
      padding: 8px;
      text-transform: capitalize;
    }
  }

  .event-time {
    display: flex;

    &-picker {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    &-duration {
      display: flex;
      flex: 0 0 110px;
      flex-direction: column;
      margin-right: 10px;
    }
  }

  .event-schedule {
    align-items: flex-end;
    display: flex;

    &-now {
      border-style: none;
      flex: 0 0 169px;

      @media (max-width: 500px) {
        flex: 0 0 100%;
        height: 42.76px;
      }
    }

    &-queue {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-x: auto;

      label {
        font-size: 7px;
      }
    }
  }

  .event-queue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-games {
      overflow-x: auto;
      overflow-y: hidden;
    }

    &-game {
      display: flex;
      overflow-x: auto;
    }
  }
}

.list_game_neaby {
  margin: 0 30px 0 10px;
  width: 40px;
}

.game_nearby_icon {
  cursor: pointer;
  height: 42.76px;
  margin-bottom: 2.5px;
  width: 42.76px;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    border-radius: 5px;
  }
}

.game_selected_container {
  display: grid;
  grid-template-areas:
    'selected-icon'
    'selected-info'
    'selected-add';

  .game_selected_name_icon {
    grid-area: selected-icon;
  }

  .game_selected_info {
    grid-area: selected-info;
  }

  .game_selected_add {
    grid-area: selected-add;
  }

  .game_selected_name_icon {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .game_selected_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;

    &_title {
      color: #1b2a4a;
      font-size: 6.03272px;
      font-style: normal;
      font-weight: bold;
      line-height: 8px;
      text-align: center;
      text-transform: uppercase;
    }

    .game_selected_description {
      color: #464646;
      font-size: 10px;
      line-height: 15px;
      width: 160px;
    }
  }

  .game_selected_add {
    align-items: center;
    background-color: #207ac4;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 36.48px;
    padding: 6px;

    .checkbox_container {
      height: 20px;
      margin-right: 8px;
      position: relative;
      width: 20px;

      input[type='checkbox'] {
        visibility: hidden;
      }

      input[type='checkbox']:checked + label::after {
        opacity: 1;
      }

      label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;

        &::after {
          border-bottom: 2px solid #207ac4;
          border-left: 2px solid #207ac4;
          content: '';
          height: 6px;
          left: 3px;
          opacity: 0;
          position: absolute;
          top: 5px;
          transform: rotate(-45deg);
          width: 12px;
        }
      }
    }

    span {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  .game_selected_add:hover {
    background: #0063b4;
  }
}

.game_selected_icon {
  height: 55.21px;
  position: relative;
  width: 54.67px;
}

.game_selected_name {
  margin-left: 12.7px;
  text-align: left;
  width: 111px;
}

.game_detail_preview {
  display: flex;
  min-height: 221.9px;
  min-width: 125.12px;
  position: relative;

  img {
    border-radius: 15px;
  }
}

.date_time_picker {
  display: flex;
  position: relative;

  svg {
    left: 10px;
    position: absolute;
    top: 5px;
  }

  input {
    align-items: center;
    background: #207ac4;
    border-radius: 20px;
    border-style: none;
    color: #fff;
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    height: 24px;
    justify-content: center;
    line-height: 14px;
    outline: none;
    padding: 4px 10px 4px 30px;
    width: 100%;
  }

  input:hover {
    background: #0063b4;
  }

  input:focus {
    border: 1px solid rgb(38 132 255);
    box-shadow: 0 0 0 1px #2684ff;
  }

  .arrow_down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
    position: absolute;
    right: 12px;
    top: 10px;
  }
}
/* end game detail */

.upcoming_events {
  z-index: 9;
}

.upcoming_events_title {
  margin-bottom: 10px;
}

/* event card */
.event_card_container {
  display: flex;

  &.justify {
    justify-content: space-between;
  }

  &:not(.justify) {
    .event_card_btn {
      &.prev {
        margin-right: 25px;
      }

      &.next {
        margin-left: 25px;
      }
    }

    .event_card {
      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }

  .event_card_btn {
    align-items: center;
    display: flex;

    &.disabled {
      button {
        background-color: #808080;
      }
    }

    button {
      align-items: center;
      background-color: #1775c2;
      border-radius: 50%;
      border-style: none;
      color: #fff;
      display: flex;
      height: 34px;
      justify-content: center;
      width: 34px;
    }
  }

  .event_card {
    background: #fff;
    border: 2px solid #ececec;
    border-radius: 3.65385px;
    box-shadow: 0 4.87179px 4.87179px rgb(0 0 0 / 20%);
    position: relative;
    width: 181px;

    &.live {
      &::after {
        align-items: center;
        background-color: #f00;
        border-radius: 3.6px;
        color: #fff;
        content: 'live';
        display: flex;
        font-size: 9px;
        font-weight: 900;
        height: 14px;
        justify-content: center;
        left: 50%;
        line-height: 10.75px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: -8px;
        transform: translateX(-50%);
        width: 57px;
      }
    }
  }
}

.event_schedule {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  display: flex;
  height: 55px;
  padding: 10px 6px 10px 8px;
  position: relative;

  .event_card.red & {
    border-bottom-color: #f00;
  }

  .event_card.blue & {
    border-bottom-color: #001aff;
  }

  .event_card.yellow & {
    border-bottom-color: #ffb800;
  }
}

.event_schedule::before {
  content: '';
  height: 26px;
  left: 0;
  position: absolute;
  top: 12px;
  width: 4.65px;

  .event_card.red & {
    background-color: #f00;
  }

  .event_card.blue & {
    background-color: #001aff;
  }

  .event_card.yellow & {
    background-color: #ffb800;
  }
}

.event_date {
  color: #464646;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 1.25855px;
  line-height: 41px;
  margin-right: 5px;
  text-align: center;
}

.event_day {
  letter-spacing: 0.464869px;
}

.event_time {
  color: #464646;
  font-size: 10px;
  font-style: normal;
  line-height: 12px;
}

.event_background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.event_icon {
  flex: 1;
  height: auto;
  height: 50px;
  max-height: 50px;
  max-width: 33.33%;
  position: relative;
}

.event-game-cnt {
  align-items: center;
  color: #393939;
  display: flex;
  font-size: 34px;
  font-weight: 800;
  justify-content: center;
  line-height: 48px;
}
/* end event card */

/* edit event */
.edit_event {
  background: #fff;
  border: 1.08172px solid #ececec;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 22px 28px;
  position: relative;

  &_title {
    align-items: center;
    color: #000;
    display: flex;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
  }

  &_sub_title {
    margin: 10px 0;
  }

  &_close {
    position: absolute;
    right: 28px;
    top: 22px;

    svg {
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }

  &_label {
    margin-bottom: 5px;
    text-transform: capitalize;
  }

  &_input {
    background: #f8f8f8;
    border: 1px solid #cdcdcd;
    border-radius: 26px;
    box-shadow: 0 0 2.65366px rgb(0 0 0 / 10%);
    color: #636363;
    font-size: 12px;
    font-weight: 500;
    height: 32px;
    line-height: 16px;
    margin-bottom: 15px;
    outline: none;
    padding: 0 17px;
    text-transform: capitalize;
    width: 100%;
  }

  &_game_container {
    display: inline-flex;
    margin-bottom: 15px;
    overflow-x: scroll;
    padding-bottom: 18px;
    width: 100%;

    &::-webkit-scrollbar {
      height: 8px;
    }
  }

  &_game_img {
    margin-right: 18px;
    position: relative;

    .img-container {
      height: 73.94px;
      width: 68.85px;
    }

    &.selected .img-container img {
      border: 2px solid #f00 !important;
      border-radius: 28px;
    }
  }

  &_start_time {
    input {
      background: #eef6ff;
      border-radius: 2.65366px;
      border-style: none;
      box-shadow: 0 0 2.65366px rgb(0 0 0 / 10%);
      color: #a6adb9;
      font-size: 10px;
      font-weight: 600;
      height: 27px;
      line-height: 12px;
      margin-bottom: 15px;
      outline: none;
      padding: 0 10px;
      text-transform: capitalize;
      width: 100%;
    }
  }

  .event-time {
    display: flex;
    margin-bottom: 26px;

    &-picker {
      flex: 1;
    }

    &-duration {
      flex: 1;
    }

    @media (min-width: 401px) {
      &-picker {
        margin-right: 20px;
      }

      &-duration {
        flex: 0 0 150px;
      }
    }
  }

  .date_time_picker {
    svg {
      left: 43px;
      top: 8px;
    }

    input {
      height: 30px;
      padding: 4px 10px 4px 70px;
    }

    .arrow_down {
      right: 20px;
      top: 13px;
    }
  }
}

.select_participants {
  align-items: center;
  background: #eef6ff;
  border-radius: 2.65366px;
  border-style: none;
  box-shadow: 0 0 2.65366px rgb(0 0 0 / 10%);
  color: #34324c;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  height: 27px;
  justify-content: space-between;
  line-height: 12px;
  margin-bottom: 15px;
  padding: 0 10px;
  text-transform: capitalize;
}

.remove_participants_btn {
  align-items: center;
  background: #dc4844;
  border-radius: 2.44561px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 10.8172px;
  font-style: normal;
  font-weight: bold;
  height: 24px;
  justify-content: center;
  line-height: 7px;
  margin: 10px 0 25px;
  text-transform: uppercase;
}

.edit_btn_container {
  @media (max-width: 400px) {
    flex-direction: column;
  }
}

.save_setting_btn {
  cursor: pointer;
  flex: 1;
  margin-right: 20px;
  text-transform: capitalize;
  width: 100%;

  @media (max-width: 400px) {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.cancel_outing {
  background: linear-gradient(0deg, #f00, #f00), #f7f7f7;
  cursor: pointer;
  flex: 0 0 150px;

  &:hover {
    background: #d50d0d;
  }

  @media (max-width: 400px) {
    flex: auto;
    margin: auto;
  }
}
/* end edit event */

/* invite member */
.event-info-box {
  border: 1px solid #ececec;
  border-radius: 10px;
  box-sizing: border-box;
}

.event-info-box-header {
  border-bottom: 1px solid #ececec;
  height: 72px;
  padding: 12px 40px 12px 0;
  position: relative;

  .color-sticker {
    height: 32px;
    margin: 0 12px 0 1px;
    width: 48px;

    &.red {
      background-color: #f00;
    }

    &.blue {
      background-color: #001aff;
    }

    &.yell & {
      background-color: #ffb800;
    }
  }

  .game-list {
    align-items: center;
    display: flex;

    @media (max-width: 500px) {
      display: none;
    }
  }

  .game-list-item {
    cursor: pointer;
    height: 2rem;
    margin-left: 6px;
    position: relative;
    width: 2rem;

    &.game-list-item-cnt {
      align-items: center;
      display: flex;
      font-size: 15px;
      font-weight: 800;
      justify-content: center;
      line-height: 18px;
      margin-left: 0;
    }
  }

  .event-date {
    color: #464646;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-right: 8px;
  }

  .event-day {
    letter-spacing: 0.650962px;
  }

  .event-time {
    letter-spacing: 0.420744px;
  }

  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;

    svg {
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }
}

.event-info-box-body {
  padding: 16px 14px 0;

  .event-meta-action-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &.not-editable {
      @media (max-width: 500px) {
        flex-direction: column;

        .event-join-now {
          flex: unset;
          height: 34px;
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  }

  .event-meta {
    align-items: center;
    display: flex;
    max-width: 100%;
    min-width: 0;
  }

  .event-icon {
    flex: 0 0 3.5rem;
    height: 3.5rem;
    margin-right: 12px;
    position: relative;
    width: 3.5rem;
  }

  .event-title-period {
    min-width: 0;
  }

  .event-title {
    letter-spacing: 0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .event-period {
    color: #464646;
    font-weight: normal;
    letter-spacing: 0.5px;
  }

  .event-edit-icon {
    cursor: pointer;
    flex: 0 0 2rem;
    margin-left: 1rem;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  .event-join-now {
    flex: 0 0 9rem;
    height: 44px;
    line-height: 16px;
    padding: 0 1rem;
  }
}

.invites-copy-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 30px 10px;

  .box-item {
    flex: 1;
    max-width: 160px;
    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: 576px) {
      margin-bottom: 20px;
      max-width: unset;
    }
  }

  .box-item-text {
    margin-top: 24px;
    padding-left: 10px;
    padding-right: 10px;
    width: 148px;
  }

  .box-item-text-fluid {
    flex: 0 0 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 280px;
    text-align: center;

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .box-item-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    svg {
      cursor: pointer;
      height: 18px;
      width: 18px;
    }
  }
}

.box-item-input {
  background: #ececec;
  border-radius: 26px;
  border-style: none;
  color: #808080;
  font-weight: 500;
  height: 34px;
  letter-spacing: 0.272577px;
  line-height: 34px;
  margin: 0 0 8px;
  overflow: hidden;
  padding: 0 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.invite-member-popup {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  filter: drop-shadow(0 15px 157px #000);
  left: 50%;
  overflow: hidden;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 530px;
  z-index: 9999;

  &-header {
    padding: 20px 16px;
  }

  &-close {
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }

  &-title {
    color: #464646;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 29px;
    padding: 0 25px;
    text-align: center;
  }

  &-body {
    background-image: url('/images/get_started_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &-content {
    // width: 330px;
    height: 250px;
    margin: auto;
    padding: 10px 50px;

    p {
      border-radius: 4px;
      color: #464646;
      font-size: 14px;
      font-weight: 600;
      height: 100%;
      overflow-y: auto;
      padding: 10px;
      // background-color: #eee;
      white-space: pre-line;
    }
  }

  &-btns {
    padding: 30px 16px 18px;
    text-align: end;
  }

  &-btn {
    border-radius: 2.3px;
    border-style: none;
    font-size: 10px;
    height: 27px;
    outline: none;
    padding: 7px 10px;
    text-transform: uppercase;

    &.action-copy {
      background-color: #2050c4;
      color: #fff;
      min-width: 147px;
    }

    &.action-cancel {
      background-color: #fff;
      border: 1px solid #bababa;
      color: #666;
      margin-left: 10px;
      min-width: 74px;
    }
  }
}

.event_repeat_section {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.repeat_event_check_label {
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0;
}

.repeat_event_check_label input[type='checkbox'] {
  display: none;
}

.repeat_event_check_label .repeat_event_checkbox_display {
  background-color: rgba(238, 115, 58, 0);
  border: 1px solid #2050c4;
  border-radius: 2px;
  flex-shrink: 0;
  height: 12.45px;
  margin-right: 5px;
  pointer-events: all;
  position: relative;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  width: 12.45px;
}

.repeat_event_check_label input:checked ~ .repeat_event_checkbox_display {
  background-color: #2050c4;
  border-radius: 1px;
  border-style: none;
}

.event_repeat_content {
  color: #464646;
  font-size: 9.13621px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}
/* end invite member */
/* user settings */
.user_setting {
  background: #f5faff;
  border: 1.08172px solid #ececec;
  border-radius: 3.31178px;
  box-sizing: border-box;
  padding: 12px;
  position: relative;
}

.user_setting_title {
  align-items: center;
  color: #000;
  display: flex;
  font-size: 17.9133px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.user_setting_sub_title {
  align-items: center;
  color: #000;
  display: flex;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 19px;
  margin-bottom: 15px;
}

.user_setting_close {
  position: absolute;
  right: 6px;
  top: 3px;
}

.user_setting_label {
  align-items: center;
  color: #1b2733;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin: 20px 0 5px;
  text-transform: capitalize;
}

.user_setting_input {
  background: #eef6ff;
  border-radius: 2.65366px;
  border-style: none;
  box-shadow: 0 0 2.65366px rgb(0 0 0 / 10%);
  color: #a6adb9;
  font-size: 10px;
  font-weight: 600;
  height: 27px;
  line-height: 12px;
  margin-bottom: 10px;
  padding: 0 10px;
  text-transform: capitalize;
  width: 100%;
}

.user_setting_input:focus {
  outline: unset;
}

.user_setting_container {
  align-items: center;
  display: flex;
  height: 25px;
  justify-content: space-between;
  padding: 0 5px;
}

.user_setting_container.bg_grey {
  background: #e6e6e6;
}

.user_secting_item {
  color: #464646;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.notification_switch {
  align-items: center;
  display: flex;
  justify-content: center;
}

.notification_switch_text {
  color: #777;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  text-align: right;
  text-transform: uppercase;
}

.notification_switch_text.on {
  color: #2050c4;
}

.notification_switch_box {
  background: #c2c7cd;
  border-radius: 14px;
  cursor: pointer;
  height: 11.59px;
  margin: 0 5px;
  position: relative;
  width: 18.44px;
}

.notification_switch_box::before {
  background: #2050c4;
  border-radius: 50%;
  content: '';
  height: 8.28px;
  left: 2px;
  position: absolute;
  top: 1.5px;
  transform: translate(6px, 0);
  transition: transform 0.3s ease-in-out;
  width: 8.28px;
}

.notification_switch_text.on ~ .notification_switch_box::before {
  transform: translate(0, 0);
}

.user_setting_policy {
  align-items: center;
  color: #2050c4;
  display: flex;
  font-size: 10.9442px;
  font-style: normal;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 5px;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.user_setting_policy.last {
  margin-bottom: 30px;
}
/* end user settings */
/* screen scale */
/*
@media (min-width: 985px) {
    .page-home {
        zoom: 1.1;
    }
}

@media (min-width: 1075px) {
    .page-home {
        zoom: 1.2;
    }
}

@media (min-width: 1164px) {
    .page-home {
        zoom: 1.3;
    }
}

@media (min-width: 1254px) {
    .page-home {
        zoom: 1.4;
    }
}

@media (min-width: 1344px) {
    .page-home {
        zoom: 1.5;
    }
}

@media (min-width: 1433px) {
    .page-home {
        zoom: 1.6;
    }
}

@media (min-width: 1523px) {
    .page-home {
        zoom: 1.7;
    }
}

@media (min-width: 1612px) {
    .page-home {
        zoom: 1.8;
    }
}

@media (min-width: 1702px) {
    .page-home {
        zoom: 1.9;
    }
}

@media (min-width: 1792px) {
    .page-home {
        zoom: 2;
    }
}
 */
/* end screen scale */

@media (max-width: 575.98px) {
  .invite-member-popup {
    width: calc(100% - 20px);

    &-title {
      font-size: 16px;
      line-height: 20px;
    }

    &-content {
      padding: 10px;

      p {
        word-wrap: break-word;
      }
    }
  }

  .head_line {
    bottom: 0;
    flex-flow: wrap;
    height: 67px;
    padding: 0 25px;
    position: absolute;
    text-decoration: none;
  }

  .join_outing {
    height: 120px;
    margin: 5px 0;
    padding-left: 33px;

    &-title {
      font-size: 14px;
    }

    &-subtitle {
      font-size: 7px;
    }

    &_form {
      &_submit {
        flex: 0 0 71px;
        font-size: 8.6px;
      }
    }
  }

  .get_started {
    margin-bottom: 0;
    padding: 20px 21px;
    width: 100%;

    &_title {
      font-size: 18px;
    }

    &_desc {
      display: none;
      margin: 7px 0;
    }

    &_choose {
      align-items: center;
      color: #636363;
      font-size: 15px;
      line-height: 18px;
      margin-top: 20px;
      text-transform: none;
    }
  }

  .get_started_btn {
    font-size: 12.8119px;
    line-height: 15px;
    padding: 8px 0;
  }

  .upcoming_events {
    overflow: auto;
    padding-bottom: 15px;
  }

  .upcoming_events_title {
    display: none;
  }

  .event_card_container {
    .event_card {
      margin: 0 10px;
    }
  }

  .create_game_collapsed {
    display: none;
  }

  .games_select {
    margin-bottom: 0;
    margin-top: 20px;
    width: 100%;

    &_header {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;

      &_scheduled {
        color: #0d6fbf;
        cursor: pointer;
        text-decoration-line: underline;
      }
    }

    &_body {
      background: #fff;
      display: flex;
      margin: 0 20px;
      max-width: 100%;
      overflow-x: scroll;
      padding: 14px 0;
    }

    &_event_scheduled {
      background: #fff;
      border: 2px solid #ececec;
      margin-bottom: 20px;

      &_header {
        align-items: center;
        border-bottom: 1px solid #ececec;
        display: flex;
        height: 30px;
        justify-content: space-between;
        padding: 0 20px;
      }

      &_body {
        border-radius: 0 0 3px 3px;
        border-top-width: 1px;
        box-sizing: border-box;
        padding: 20px;
      }
    }
  }

  .games_select_header_action {
    display: none;
  }

  .games_select_status {
    display: none;
  }

  .games_select_container {
    border-radius: 24px;
    margin: 0 0 0 5px !important;

    .games_select_img {
      height: 60px;
      width: 56px;
    }
  }

  .game_detail {
    width: auto;

    &_header {
      display: none;
    }

    &_body {
      justify-content: space-between;
      padding: 20px 30px;
    }

    &_footer {
      padding: 20px 30px;
    }

    .event-title {
      flex: 0 0 105px;
    }
  }

  .game_detail_preview {
    min-height: 165.36px;
    min-width: 93.24px;
  }

  .list_game_neaby {
    display: none;
  }

  .game_selected_container {
    //grid-template-areas:
    //    "selected-icon selected-icon"
    //    "selected-info selected-info"
    //    "selected-add selected-add";

    .game_selected_info {
      margin-bottom: 20px;
    }

    .game_selected_add {
      span {
        font-size: 10px;
      }
    }
  }

  .game_selected_icon {
    height: 60.03px;
    position: relative;
    width: 55.9px;

    img {
      border-radius: 22px;
    }
  }

  .game_selected_name {
    width: 75px;
  }

  .game_selected_participants {
    display: none;
  }

  .edit_event {
    width: 100%;

    .event-time {
      margin-bottom: 0;

      &-duration {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }

  .edit_event_close {
    right: 15px;
    top: 20px;
  }

  /* invite member */
  .invites-copy-section {
    display: block;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 320px) {
  .game_detail {
    &_footer {
      .event-time {
        &-duration {
          display: flex;
          flex: 0 0 96px;
          flex-direction: column;
        }
      }
    }
  }
}

@media (max-width: 300px) {
  .get_started {
    &_choose {
      margin-top: 12px;
    }
  }

  .game_detail {
    &_footer {
      padding: 20px 10px;
    }

    &_body {
      padding: 20px 10px;
    }
  }

  .join_outing {
    padding-left: 28px;

    &_form {
      &_input {
        flex: 0;
        width: 135px;
      }
    }
  }

  .flatpickr-calendar {
    left: 2px !important;
    padding: 12px !important;
    width: 290px !important;
  }

  .flatpickr-monthDropdown-months {
    width: 120px !important;
  }
}
