.lobby-leaderboard {
  background-color: #fff;
  border: 2px solid #ececec;
  border-radius: 0 0 10px 10px;
  border-top-width: 0;
  overflow: auto;
  padding: 1rem;

  .tr {
    align-items: center;
    display: flex;
    padding: 0.5rem 0;

    &:not(:last-child) {
      border-bottom: 2px solid #f6f7fa;
    }

    &.table-header {
      color: #8d8d8d;
      font-size: 15px;
      font-weight: 700;
      line-height: 16px;
    }
  }

  .col-place {
    flex: 0 0 4rem;
    text-align: center;
  }

  .col-user {
    flex: 1;
    margin-left: 1rem;
  }

  .col-points {
    flex: 0 0 180px;
    margin-left: 1rem;
  }

  .table-body {
    max-height: 630px;
    overflow-y: auto;

    .col-place {
      color: #b9b9b9;
      font-size: 15px;
    }

    .col-user {
      .user-avatar {
        border: 1px solid #808080 !important;
        border-radius: 50%;
        height: 22px;
        overflow: hidden;
        width: 22px;
      }

      .user-name {
        color: #464646;
        font-size: 12px;
        margin-left: 0.5rem;
      }
    }

    .col-points {
      color: #5d34a6;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;

      span + span {
        // suffix
        font-size: 8px;
        margin-left: 1px;
        text-align: center;
        text-transform: uppercase;
        vertical-align: text-top;
      }

      .col-round-point {
        color: #9673d3;
        font-size: 12px !important;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 14px;
        text-transform: initial;
        vertical-align: middle !important;

        &::before {
          color: rgb(166, 160, 160);
          content: '|';
          margin: 0 6px;
        }
      }
    }
  }

  .round-navs {
    align-items: center;
    display: flex;
    justify-content: center;

    .nav-icon {
      cursor: pointer;
      padding: 4px;

      svg {
        height: 18px;
        width: 18px;
      }

      &:last-child {
        margin-left: 8px;
        transform: rotate(180deg);
      }
    }

    div {
      height: 22px;
      margin: 0 8px !important;
      width: 22px;

      img {
        border-radius: 50%;
      }
    }

    .round-text {
      text-align: center;
      white-space: nowrap;
      width: 70px;
    }
  }

  // Default View
  &.is-compact-mode {
    &:not(.is-finished-screen) {
      border-style: none;
      padding: 0.5rem 0;
    }

    .col-place {
      flex: 0 0 6rem;
    }

    // Event Finished Screen
    &.is-finished-screen {
      .table-body {
        font-weight: 700;
      }
    }
  }
}
