.mobile-game-topbar {
  height: 3rem;

  .block-status {
    align-items: center;
    background-color: #f0f0f0;
    color: #0c0c0c;
    display: flex;
    font-weight: 700;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    .status-rank {
      color: #000;
      font-size: 15px;
      letter-spacing: 0.8px;
    }

    .status-score {
      color: #5d34a6;
      font-size: 15px;
      letter-spacing: 1.05px;
    }

    .status-avatar {
      align-items: center;
      display: flex;

      img {
        border-radius: 50%;
      }

      span.user-name {
        color: #464646;
        font-size: 12px;
        letter-spacing: 0.8px;
      }
    }
  }
}
