@font-face {
  font-display: block;
  font-family: 'Mont';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Mont-W05-Book.woff2') format('woff2'),
    url('/fonts/Mont-W05-Book.woff') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Mont';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Mont-W05-Bold.woff2') format('woff2'),
    url('/fonts/Mont-W05-Bold.woff') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Mont';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/Mont-W05-Heavy.woff2') format('woff2'),
    url('/fonts/Mont-W05-Heavy.woff') format('woff');
}

html,
body {
  font-family: 'Mont', sans-serif;
  font-weight: 400;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
}

#__next {
  display: flex;
}

#__next > * {
  width: 100%;
}

.hide {
  display: none !important;
}

.btn.disabled {
  background: radial-gradient(61.29% 100% at 50% 50%,
  rgba(255, 255, 255, 0.2) 0%,
  rgba(255, 255, 255, 0) 100%),
    #cecece;
  border-style: none;
  pointer-events: none;
}

.main_body {
  background-color: #caf0ff;
  min-height: calc(100vh - 55px);
  padding-bottom: 32px;
  position: relative;
}

.app-wrapper {
  min-height: 100vh;
  overflow-x: hidden;
}

.main-app-version {
  background-color: #f5faff;
  padding: 5px;
  text-align: end;
  width: 100%;
}

.input_group_field {
  border: 1px solid #cbcbcb;
  border-radius: 25px;
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 500;
  height: 36.48px;
  line-height: 16px;
  outline: none;
  padding: 10px 15px;
  width: 286.77px;
}

.input_group_btn {
  align-items: center;
  background: #207ac4;
  border-radius: 25px;
  color: #fff;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  height: 36.48px;
  justify-content: center;
  line-height: 24px;
  width: 96.85px;
}

.input_group_btn:hover {
  background: #0063b4;
}

.button_big {
  align-items: center;
  background: #207ac4;
  border-radius: 72px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  height: 44px;
  justify-content: center;
  line-height: 160%;
  width: 162px;
}

.button_small {
  align-items: center;
  background: #1775c2;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  line-height: 133%;
  width: 141px;
}

.button_big:hover,
.button_small:hover {
  background: #0063b4;
}

.tg-btn-primary {
  background: #1775c2;
  border-radius: 20px;
  border-style: none;
  color: #fff;
  font-weight: 600;
  width: 100%;
}

.tg-btn-primary:hover {
  background: #0063b4;
}

.tg-btn-small {
  font-size: 12px;
  height: 34px;
  line-height: 14px;
}

.tg-btn-medium {
  font-size: 15px;
  height: 44px;
  line-height: 18px;
}

.hero {
  color: #000;
  font-size: 64px;
  font-weight: 800;
  line-height: 94%;
}

.date_large {
  color: #000;
  font-size: 62px;
  font-weight: 600;
  letter-spacing: 2.18013px;
  line-height: 100%;
}

.date_small {
  color: #000;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 1.58296px;
  line-height: 141%;
}

.heading_1 {
  color: #000;
  font-size: 37px;
  font-weight: 800;
  line-height: 124%;
}

.heading_2 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
}

.heading_3 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 133%;
}

.heading_4 {
  color: #000;
  font-size: 15px;
  font-weight: bold;
  line-height: 160%;
}

.description_large {
  color: #000;
  font-size: 18px;
  line-height: 133%;
}

.description_medium {
  color: #000;
  font-size: 15px;
  line-height: 160%;
}

.description_small {
  color: #000;
  font-size: 12px;
  line-height: 133%;
}

.large_btn {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 160%;
  text-align: center;
}

.small_btn {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 133%;
  text-align: center;
}

::-webkit-scrollbar-track {
  background-color: #e1e1e1;
  border-radius: 18px;
}

::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border-radius: 18px;
}

.blink-me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.custom-img {
  height: unset !important;
  object-fit: contain;
  position: relative !important;
  width: 100% !important;
}

.unset-img {
  width: 100%;
}

.unset-img > div {
  position: unset !important;
}

@media (max-width: 1280px) {
  .hero {
    font-size: 46px;
  }

  .date_small {
    font-size: 34px;
  }

  .large_btn {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .heading_1 {
    font-size: 23px;
  }

  .heading_2 {
    font-size: 18px;
  }

  .heading_3 {
    font-size: 18px;
  }

  .heading_4 {
    font-size: 14px;
  }

  .description_large {
    font-size: 12px;
  }

  .description_medium {
    font-size: 12px;
  }

  .button_big {
    width: 150px;
  }
}

@media (max-width: 300px) {
  .heading_1 {
    font-size: 20px;
  }

  .heading_4 {
    font-size: 13px;
  }
}
