// styles (with excess) lifted from boom.css, base.css from server repo

.footer_container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  max-width: 920px;
}

.about_links {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
}

.about_links.personal_data {
  justify-content: flex-start;
  padding-top: 15px;
}

.about_link {
  color: rgba(238, 115, 58, 0.75);
  font-family: 'Avant Garde';
  font-size: 14px;
  margin-right: 25px;
  overflow: hidden;
  padding-bottom: 1px;
  position: relative;
  transition: color 0.2s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  will-change: color;
}

.about_link::before {
  background-color: #ee733a;
}

.about_link:hover {
  color: #ee733a;
}

.about_us {
  align-items: center;
  display: flex;
  margin-bottom: 30px;
}

.about_logo {
  align-items: center;
  display: flex;
  height: 20px;
  margin-right: 25px;
  position: relative;
  width: 134px;
  width: 134px;
}

.about_info {
  color: rgba(106, 108, 123, 0.75);
  font-family: 'Avant Garde';
  font-size: 14px;
}

@media all and (max-width: 978px) {
  .about_links {
    justify-content: flex-start;
  }
}
@media all and (max-width: 610px) {
  /* FOOTER */
  .footer_container {
    flex-flow: wrap;
    justify-content: center;
    text-align: center;
  }

  .footer_about {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }

  .about_us {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
  }

  .about_logo {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .about_info {
    width: 100%;
  }

  .about_links {
    flex-flow: wrap;
  }

  .about_link {
    margin: 0 0 10px;
    width: 100%;
  }

  .about_link:hover::before {
    transform: translate(0%, 0);
  }

  .about_link:last-child {
    margin-right: 0;
  }

  .social_title {
    margin-bottom: 15px;
    text-align: center;
  }

  .social_link:first-child {
    margin-left: 0;
  }
  /* FOOTER Ending*/

  /* Boom Alert */
  .boom_alert {
    right: 0;
    top: 60px;
    width: 100%;
  }

  .boom_alert_item {
    padding: 10px 15px;
  }

  .boom_alert_container {
    padding-right: 15px;
  }

}
@media all and (max-width: 558px) {
  .nav_container {
    padding: 10px 5px 10px 0;
  }

  .nav_container.highschool_landing .nav_links_second .dropdown_header {
    display: none;
  }

  .dropdown_header {
    margin: 0 5px !important;
  }

  .nav_logo {
    background: unset;
    border-style: none;
    padding: 5px 10px;
    width: auto !important;
  }

  .nav_logo i {
    height: 30px;
    width: 105px;
  }

  .nav_left {
    justify-content: space-between;
    position: relative;
    width: calc(220% / 3);
  }

  .nav_hamburger .btn_menu_bars {
    height: 13px;
  }

  .nav_hamburger .menu_bar {
    border-radius: 0;
  }

  .nav_hamburger .menu_bar:nth-child(2) {
    width: 16px;
  }

  .nav_hamburger .menu_bar:last-child {
    display: none;
  }

  .nav_logo_dropdown {
    flex-direction: unset;
  }

  .nav_logo_other {
    display: none;
  }

  .nav_logo_on .nav_logo_title {
    display: none;
  }

  .nav_logo_arrow {
    display: block;
  }

  .nav_link_drop {
    display: none;
  }

  #nav_link_dropdown {
    display: none;
  }

  .avgl .nav_logo {
    width: 65px;
  }

  .nav_inputholder {
    display: none;
  }

  .link_image_platform {
    width: 17px;
  }

  .link_image_platform.user_type_6 {
    width: 26px;
  }

  .video_info {
    justify-content: flex-start;
    max-width: 234px;
  }

  .video_properties {
    margin-bottom: 30px;
    margin-right: 30px;
  }

  .related_cards {
    justify-content: center;
  }

  .about_links {
    flex-flow: wrap;
    justify-content: space-around;
  }

  .about_links.personal_data {
    justify-content: space-around;
    padding-top: 0;
  }

  .about_link,
  .about_link:last-child {
    margin: 0 10px 10px;
  }
  /* BROWSE */
  /* Related Nav */
  .related_nav {
    flex-flow: wrap;
  }

  .related_types {
    justify-content: space-between;
    margin: auto;
    order: 2;
    width: 400px;
  }

  .related_nav .inputholder {
    margin: 0 auto 20px;
    max-width: 400px;
    width: 100%;
  }

  .types_game {
    max-width: 150px;
    min-width: 121px;
    width: 100%;
  }

  .types_sort {
    max-width: 170px;
    min-width: 141px;
    width: 100%;
  }
  /* RELATED End*/
  .modal_login_general .admin_login_box {
    padding: 50px 50px 0;
  }

  .modal_login_general .admin_login_title {
    font-size: 24px;
  }

  .modal_login_general .admin_login_more_btns {
    display: block;
  }

  .modal_login_general .admin_login_label {
    font-size: 14px;
  }

  .modal_login_general .admin_login_line {
    flex-flow: wrap;
  }

  .modal_login_general .admin_login_more {
    margin: 0 -50px;
  }

  #dropdown_header_hover {
    align-items: center;
    display: flex;
    height: auto;
  }

  .nav_logo.avgl {
    border-bottom-style: none;
    padding: 4.5px 20px;
  }

  .nav_logo.avgl i {
    height: 30px !important;
    width: 90px !important;
  }

  .nav_logo_on.queueup {
    padding: 5.5px 10px;
  }
}
