.lobby-banner-finished {
  align-items: center;
  background-color: #f6f7fa;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  @media (min-width: 992px) {
    border-bottom: 2px solid #5d34a6;
    border-left: 20px solid #5d34a6;
    border-radius: 10px 10px 0 0;
  }

  .block-left {
    flex: 1;
    padding: 0.75rem 1rem;
  }
    
  .block-right {
    align-items: center;
    display: flex;
    flex: 0 0 12rem;
    flex-direction: column;
    padding: 1rem 1.5rem 0.75rem;
    z-index: 1;
    @media (min-width: 992px) {
      padding: 1rem 1.5rem 0.5rem;
    }
  }

  .text-subtitle {
    color: #101010;
    font-size: 0.75rem;
    line-height: 16px;
    @media (min-width: 992px) {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  .text-title {
    color: #101010;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    @media (min-width: 992px) {
      font-size: 28px;
      line-height: 40px;
    }
  }

  .yellow-trophy {
    margin-bottom: 0.25rem;

    img {
      height: 5rem;
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  .gray-circle {
    background-color: transparent;
    border: 1.75rem solid #ebebeb;
    border-radius: 50%;
    height: 11rem;
    position: absolute;
    right: 0.5rem;
    width: 11rem;
  }
}

@media (max-width: 400px) {
  .lobby-banner-finished {
    .block-left {
      flex: 0 0 100px;

      .text-title {
        margin-top: 10px;
      }
    }
        
    .block-right {
      display: none;
    }

    .gray-circle {
      right: -90px;
      top: 20px;
    }
  }
}
