@font-face {
  font-family: 'Avant Garde';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/AvantGardeDemiBT.ttf') format('truetype');
}

.nav_header {
  color: #2d2d2d;
  height: 80px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;

  &.white {
    background: #fdfdfd;

    .block_right {
      .nav_menu {
        .nav_button {
          background: #207ac4;
          border: 2px solid #207ac4;

          span {
            color: #fff;
          }

          .arrow_button {
            background-image: url('/images/home/arrow_btn_white.png');
          }
        }

        .nav_button:hover {
          background: #0063b4;
          border: 2px solid #0063b4;
        }

        a {
          color: #545454;
        }
      }
    }
  }

  .header_logo {
    height: 80px;
    width: 220px;

    &.small {
      height: 51px;
      width: 79px;
    }
  }
}

#home_page .nav_header,
#help_details_page .nav_header,
#support_page .nav_header,
#faq_page .nav_header,
#about_page .nav_header,
#wpBlog_page .nav_header,
#news_page .nav_header,
#content_not_found_page .nav_header {
  position: fixed;
}

.nav_header_inner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  max-width: 1184px;
  padding: 20px 0;
}

.block_left {
  align-items: center;
  display: flex;

  .back-button {
    cursor: pointer;
    margin-right: 0.7rem;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  .header_logo {
    cursor: pointer;
    position: relative;
  }

  .nav_logo_text {
    color: #323232;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.3502px;
    line-height: 32px;
    margin-left: 15px;
  }
}

.block_center {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}

.block_right {
  align-items: center;
  display: flex;

  .nav_menu_container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 480px;

    .nav_menu {
      cursor: pointer;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-align: center;

      .nav_button {
        align-items: center;
        background: #ffe34f;
        border: 2px solid #ffe34f;
        border-radius: 75px;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 147px;

        span {
          color: #000;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          margin-right: 12px;
          text-align: center;
        }

        .arrow_button {
          background-image: url('/images/home/arrow_btn_black.png');
          background-repeat: no-repeat;
          background-size: cover;
          height: 13.44px;
          width: 14px;
        }
      }

      .nav_button:hover {
        background: #ffcd4f;
        border: 2px solid #ffcd4f;
      }

      a {
        color: #fff;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      .price_main_title {
        text-decoration-line: line-through;
      }

      .price_sub_title {
        color: #207ac4;
        font-size: 15.9168px;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }

  .nav_user_container {
    align-items: center;
    display: flex;
    height: 50px;

    .nav_user {
      color: #2c8cf4;
      cursor: pointer;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      margin: 0 25px;
    }
  }
}

.nav_alert {
  align-items: center;
  background: #f00;
  border-radius: 50%;
  box-shadow: 0 0 5.03107px #f00;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 15.9991px;
  font-style: normal;
  font-weight: bold;
  height: 20.6px;
  justify-content: center;
  line-height: 22px;
  width: 20.6px;
}

.nav_alert_container {
  background: #f00;
  border-radius: 5.03107px;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  padding: 6px 10px;
  position: absolute;
  text-align: center;
  top: 100%;
  z-index: 9;
}

.nav_alert_container::before {
  border-bottom: 8px solid #f00;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top-style: none;
  content: '';
  position: absolute;
  right: 15px;
  top: -8px;
}

.nav_alert_container .text_alert {
  font-size: 14px;
}

.nav_alert_container .text_alert_dots {
  font-size: 16px;
}

.nav_alert_container svg {
  height: 12px;
  width: 12px;
}

.nav_user_name {
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  margin-right: 10px;
  text-align: center;
}

.nav_user_logo img {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

@media (max-width: 1440px) {
  .nav_header {
    height: 80px;
  }

  .nav_header_inner {
    padding: 10px 0;
  }

  .block_left {
    .nav_logo_text {
      font-size: 20px;
    }
  }

  .block_right {
    .nav_menu_container {
      height: 40px;

      .nav_menu {
        font-size: 20px;
        margin: 0 10px;
      }
    }

    .nav_user_container {
      height: 40px;

      .nav_user {
        font-size: 20px;
        margin: 0 10px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .nav_header_inner {
    max-width: 1024px;
  }
}

@media (max-width: 1024px) {
  .nav_header_inner {
    max-width: 904px;
    padding: 10px 0;
  }

  .block_left {
    .nav_logo_text {
      font-size: 18px;
    }
  }

  .block_right {
    .nav_menu_container {
      width: 420px;

      .nav_menu {
        font-size: 18px;
      }
    }

    .nav_user_container {
      .nav_user {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 768px) {
  .nav_header_inner {
    max-width: unset;
    padding: 10px 20px;
  }
}

@media (max-width: 650px) {
  .nav_header {
    height: 67px;
    top: 0;
    width: 100%;
    z-index: 999;

    &.white {
      box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    }

    .nav_header_inner {
      padding: 10px 35px 10px 15px;

      .block_right {
        .nav_menu_container {
          width: auto;
        }
      }
    }

    .nav_mobile_menu {
      background: #fff;
      height: 100vh;
      opacity: 0;
      position: relative;
      transition: visibility 0s, opacity 0.5s linear;
      visibility: hidden;
      z-index: 999;

      &.show {
        opacity: 1;
        visibility: visible;
      }

      .nav_shadow {
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
        height: 4px;
        margin-top: -4px;
      }

      .nav_mobile_section {
        align-items: center;
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
        display: flex;
        height: 80px;
        justify-content: center;

        a {
          color: #626262;
          font-size: 24px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          text-decoration: none;
        }
      }

      .nav_mobile_button {
        align-items: center;
        background: #207ac4;
        border-radius: 49px;
        bottom: 10px;
        color: #fff;
        display: flex;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        height: 85px;
        justify-content: center;
        left: 0;
        line-height: 24px;
        margin: 0 auto;
        padding: 0 15px;
        position: fixed;
        right: 0;
        text-align: center;
        width: calc(100% - 35px);
      }
    }
  }
}
