#confetti {
  bottom: 0;
  height: 100%;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: -1;

  @media (min-width: 1500px) {
    height: auto;
    width: auto;
  }
}

.screen-event-finished {
  padding-top: 30px;

  .row {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 6px 11px rgba(0, 0, 0, 0.25);
    padding: 40px 28px;
  }

  .col {
    z-index: 1;
  }

  .col:last-child {
    box-sizing: content-box;
    max-width: 460px;
  }

  .confetti_header {
    margin-bottom: 30px;
  }

  .confetti_description {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    margin: 20px auto;
    max-width: 310px;
    text-align: center;
  }

  .back-buttons-wrapper {
    display: flex;
    justify-content: center;

    button {
      background: linear-gradient(0deg, #207ac4, #207ac4), #f7f7f7;
      border-radius: 25px;
      font-size: 15px;
      font-weight: 800;
      height: 46px;
      line-height: 24px;
      padding: 0;
      width: 183px;
    }
  }

  @media (min-width: 992px) {
    padding: 120px 0 3rem;
  }
}

@media (max-width: 400px) {
  .screen-event-finished {
    .col {
      padding: 0;
    }
  }
}
