.page-event-lobby .game-session-iframe {
  position: relative;

  .game-content {
    margin: auto;
  }

  .info-header {
    align-items: center;
    color: #040404;
    display: flex;
    margin-bottom: 0.75rem;

    button {
      height: 26px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .info-header-text {
    margin-left: 0.5rem;
  }

  .line-for-round {
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
  }

  .line-for-timer {
    align-items: center;
    display: flex;
    font-size: 23px;
    font-weight: 700;
    line-height: 26px;

    img {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .text-alert {
    color: #f00;
  }

  iframe {
    display: block;
    height: 100%;
    width: 100%;
  }

  .info-footer {
    background-color: #fdfdfd;
    border: 2px solid #f00;
    border-radius: 2.5px;
    font-size: 15px;
    font-weight: 700;
    margin-top: 1.25rem;
    padding: 1rem 1.25rem;
  }
}
