.screen-main-lobby {
  background-color: #fff;
  border-radius: 1.5rem;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 1rem;
  position: relative;

  @media (min-width: 992px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    padding: 2.5rem;
  }

  .col {
    z-index: 8;
  }

  .col:first-child {
    position: relative;
    z-index: 9;
  }

  .buttons-group {
    margin-bottom: 1rem;

    button {
      border-radius: 2px;
    }
  }

  .join-game-row {
    button {
      border-radius: 24px;
      font-size: 15px;
      height: 40px;
      width: 100%;
    }
  }

  .game-note-block {
    background: #fff;
    border: 2px solid #f00;
    border-radius: 2px;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    padding: 14px 24px;

    .text-note {
      color: #f00;
    }
  }

  .styled-block {
    background: #fff;
    border: 2px solid #ececec;
    border-radius: 10px;
    overflow: hidden;

    .block-header {
      border-bottom: 2px solid #5d34a6;
      border-left: 13px solid #5d34a6;
      color: #000;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      padding: 0 8px;
    }

    .block-body {
      color: #000;
      font-size: 15px;
      line-height: 21px;
      padding: 0;

      @media (min-width: 992px) {
        padding: 20px 24px;
      }
    }
  }
}
