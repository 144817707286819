.game-info-block {
  background: #fff;
  border: 2px solid #ececec;
  border-radius: 10px;
  overflow: hidden;
  z-index: 9;

  .game-alert {
    display: inline-block;
    margin-left: 10px;
  }

  .game-alert-signal {
    align-items: center;
    background: #ffcd4f;
    border-radius: 50%;
    box-shadow: 0 0 5.03107px #ffcd4f;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-style: normal;
    height: 20.6px;
    justify-content: center;
    line-height: 22px;
    width: 20.6px;
  }

  .game-alert-content {
    background: #ffcd4f;
    border-radius: 5.03107px;
    color: #000;
    font-size: 12px;
    line-height: 14px;
    padding: 20px 10px;
    position: absolute;
    right: 5px;
    top: 40px;
    z-index: 1;

    @media (max-width: 400px) {
      padding: 10px;
      right: 10px;
    }
  }

  .game-alert-content::before {
    border-bottom: 8px solid #ffcd4f;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top-style: none;
    content: '';
    left: 112px;
    position: absolute;
    top: -8px;
  }

  .block-header {
    border-bottom: 2px solid #5d34a6;
    border-left: 13px solid #5d34a6;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    padding: 0 8px;
    position: relative;

    @media (min-width: 992px) {
      border-left-width: 16px;
    }
  }

  .block-body {
    color: #464646;
    padding: 1rem;
  }

  .game-logo {
    flex: 0 0 5rem;
    height: 88px;
    position: relative;

    img {
      width: 100%;
    }
  }

  .game-details {
    .game-title {
      color: #000;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    .game-duration {
      font-size: 15px;
      line-height: 24px;
    }

    .one-liner {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .carousel {
    margin-top: 1rem;

    p {
      color: #858585;
      font-size: 12px;
      font-style: italic;
      line-height: 16px;
    }

    .list {
      display: flex;
      flex-wrap: nowrap;
      min-height: 7rem;
      overflow-x: auto;
      padding-bottom: 10px;
      width: 100%;

      div {
        border-radius: 10px;

        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .buttons {
    .btn {
      background: #1775c2;
      border-radius: 25px;
      color: #fff;
      flex: 3;
      font-size: 12px;
      font-weight: 700;
      height: 30px;
      line-height: 16px;
      @media (min-width: 992px) {
        font-size: 14px;
      }
    }

    .btn + .btn {
      flex: 2;
    }

    .btn:hover {
      background: #0063b4;
    }
  }
}

@media (max-width: 400px) {
  .game-info-block {
    .block-body {
      padding: 22px 20px !important;
    }

    .game-logo {
      flex: 0 0 2rem;
      height: 32px;
    }

    .block-body {
      > div {
        align-items: center;
      }
    }
  }
}
