/* Paragraph */
.has-huge-font-size {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.has-large-font-size {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.has-medium-font-size {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.has-normal-font-size {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.has-small-font-size {
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

/* WP buttons */
.wp-block-button {
  border-radius: 25px;
  cursor: pointer;
  display: inline-block;
  margin: 8px 0;
  min-width: 162px;

  &__link {
    
    align-items: center;
    background-color: #0063b4;
    border-radius: 1.55em;
    box-shadow: none;
    color: #fff;
    color: #fff !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    overflow-wrap: break-word;
    padding: 8px 16px;
    position: relative;

    &:hover {
      text-decoration: none !important;
    }
  }
}

/* Tags and Tag Clouds */
.wp-block-tag-cloud {
  --gap: 5px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
}

.wp-block-tag-cloud > * {
  margin: var(--gap) 0 0 var(--gap);
}

.tag-cloud-link {
  align-items: center;
  background-color: #e1e1e1;
  border-radius: 34px;
  display: flex;

  font-size: 12px !important;
  font-weight: 500;
  height: 21px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 16px;
  min-width: 103px;
  padding: 0 10px;

  &[aria-label*='1 item'] {
    color: #000;

    &:hover {
      color: #000;
    }
  }

  &[aria-label*='2 item'],
  &[aria-label*='3 item'],
  &[aria-label*='4 item'] {
    background-color: #8d8d8d;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &[aria-label*='6 item'],
  &[aria-label*='7 item'],
  &[aria-label*='8 item'] {
    background-color: #545454;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

/* Cover Image with Text */
.wp-block-cover {
  align-items: center;
  display: flex;
  height: 200px;
  overflow: hidden;
  position: relative;

  &__inner-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    * {
      color: #fff !important;
    }
  }

  p {
    color: #fff;
    font-size: 37px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 46px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  p:hover {
    color: #fff;
  }

  img {
    width: 100% !important;
  }
}

/* WP Columns */
.wp-block-columns {
  display: flex;
}

.wp-block-column {
  flex: 1;
}

.wp-block-column:not(:last-child) {
  margin-right: 22px;
}

.blocks-gallery-grid,
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0 !important;
  padding: 0;

  .blocks-gallery-image,
  .blocks-gallery-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0 1em 1em 0;
    position: relative;
    width: calc(50% - 1em);

    figure {
      height: 100%;
      margin: 0;
    }

    img {
      flex: 1;
      height: 100%;
      object-fit: cover;
    }
  }

  .blocks-gallery-image,
  .blocks-gallery-item {
    .columns-3 & {
      margin-right: 1em;
      width: calc(33.33333% - 0.66667em);
    }

    .columns-8 & {
      margin-right: 1em;
      width: calc(12.5% - 0.875em);
    }

    .columns-4 & {
      margin-right: 1em;
      width: calc(25% - 0.75em);
    }
  }

  &.columns-1 .blocks-gallery-image:nth-of-type(1n),
  &.columns-1 .blocks-gallery-item:nth-of-type(1n),
  &.columns-2 .blocks-gallery-image:nth-of-type(2n),
  &.columns-2 .blocks-gallery-item:nth-of-type(2n),
  &.columns-3 .blocks-gallery-image:nth-of-type(3n),
  &.columns-3 .blocks-gallery-item:nth-of-type(3n),
  &.columns-4 .blocks-gallery-image:nth-of-type(4n),
  &.columns-4 .blocks-gallery-item:nth-of-type(4n),
  &.columns-5 .blocks-gallery-image:nth-of-type(5n),
  &.columns-5 .blocks-gallery-item:nth-of-type(5n),
  &.columns-6 .blocks-gallery-image:nth-of-type(6n),
  &.columns-6 .blocks-gallery-item:nth-of-type(6n),
  &.columns-7 .blocks-gallery-image:nth-of-type(7n),
  &.columns-7 .blocks-gallery-item:nth-of-type(7n),
  &.columns-8 .blocks-gallery-image:nth-of-type(8n),
  &.columns-8 .blocks-gallery-item:nth-of-type(8n),
  &.columns-1 .blocks-gallery-image:nth-of-type(1n),
  &.columns-1 .blocks-gallery-item:nth-of-type(1n),
  &.columns-2 .blocks-gallery-image:nth-of-type(2n),
  &.columns-2 .blocks-gallery-item:nth-of-type(2n),
  &.columns-3 .blocks-gallery-image:nth-of-type(3n),
  &.columns-3 .blocks-gallery-item:nth-of-type(3n),
  &.columns-4 .blocks-gallery-image:nth-of-type(4n),
  &.columns-4 .blocks-gallery-item:nth-of-type(4n),
  &.columns-5 .blocks-gallery-image:nth-of-type(5n),
  &.columns-5 .blocks-gallery-item:nth-of-type(5n),
  &.columns-6 .blocks-gallery-image:nth-of-type(6n),
  &.columns-6 .blocks-gallery-item:nth-of-type(6n),
  &.columns-7 .blocks-gallery-image:nth-of-type(7n),
  &.columns-7 .blocks-gallery-item:nth-of-type(7n),
  &.columns-8 .blocks-gallery-image:nth-of-type(8n),
  &.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}


.blocks-gallery-caption {
  font-size: 15px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
}

.has-drop-cap:not(:focus)::first-letter {
  float: left;
  font-size: 64px;
  font-weight: 900;
  line-height: 46px;
  margin-right: 1rem;
  text-transform: uppercase;
}

.has-drop-cap {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.wp-block-separator.is-style-wide {
  border-bottom-width: 1px;
}

.wp-block-quote,
.wp-block-pullquote {
  p {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }

  cite {
    color: #8d8d8d;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    text-align: left;

    &::before {
      content: '- ';
    }
  }
}

.wp-block-quote {
  border-left: 10px solid #5d34a6;
  padding-left: 45px;
}

.wp-block-pullquote {
  border-bottom: 4px solid #ffcd4f;
  border-top: 4px solid #ffcd4f;
  padding-top: 20px;
  text-align: center;
}

.wp-block-code,
.wp-block-verse {
  white-space: pre-wrap;
}

.wp-block-video {
  video {
    height: auto;
    width: 100%;
  }
}

.wp-block-audio {
  audio {
    width: 100%;
  }
}

figcaption {
  font-size: 15px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 15px;
  text-align: left;
}

.wp-block-media-text {
  direction: ltr;
  display: grid;
  grid-template-rows: auto;

  &__media {
    align-self: center;

    img,
    video {
      max-width: unset;
      vertical-align: middle;
      width: 100%;
    }

    .has-media-on-the-right & {
      grid-column: 2;
      grid-row: 1;
    }
  }

  &__content {
    align-self: center;
    direction: ltr;
    grid-column: 2;
    grid-row: 1;
    padding: 0 8%;
    word-break: break-word;

    .has-media-on-the-right & {
      grid-column: 1;
      grid-row: 1;      
    }
  }
}

.alignwide {
  margin-left: -100px;
  margin-right: -100px;
  max-width: 100vw;
}

.entry-content {
  h1 {
    margin-top: 50px;
  }

  h3 {
    margin-top: 30px;
  }
}

.has-text-align-center {
  text-align: center;
}

.wp-block-cover.alignfull {
  margin-left: calc(-100vw / 2 + 100% / 2);
  margin-right: calc(-100vw / 2 + 100% / 2);
  max-width: 100vw;
  min-height: 430px;
  width: 100vw;
}

.wp-block-search {
  &__label {
    width: 100%;
  }

  &__inside-wrapper {
    display: flex;
    flex: auto;
    flex-wrap: nowrap;
    max-width: 100%;
  }

  &__input {
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #949494;

    border-radius: 4px;
    color: #50556c;
    display: block;
    flex-grow: 1;
    font-size: 18px;
    line-height: 1.5;
    min-width: 3em;
    outline-offset: -2px;
    padding: 0.375rem 0.75rem;

    &:focus {
      background-color: #fff;
      border-color: #6f76d9;
      color: #50556c;
      outline: 0;
    }
  }

  &__button {
    background: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #32373c;
    line-height: 1;
    margin-left: 0.625em;
    padding: 0.375em 0.625em;
    word-break: normal;
  }
}

.wp-block-buttons {
  display: flex;
  flex-direction: row;

  > .wp-block-button {
    display: inline-block;
    margin-bottom: 0.5em;
    margin-left: 0;
    margin-right: 0.5em;

    &:last-child {
      margin-right: 0;
    }

    &__width-50 {
      width: calc(50% - 0.5em);
    }
  }
}
