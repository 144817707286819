.group-leaderboard {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  z-index: 9;

  .header-block {
    align-items: center;
    background-color: #f6f7fa;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    height: 77px;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
  }

  .header-block-left {
    align-items: center;
    display: flex;
    flex: 0 0 12.5rem;
    flex-direction: column;
    padding: 1rem 1.5rem 0.5rem;
    z-index: 1;

    .yellow-trophy {
      max-width: 47px;
    }
  }

  .header-block-right {
    flex: 1;
    padding: 0.75rem 1rem;
    text-align: right;

    .group-name {
      color: #111;
      font-size: 14px;
      margin-bottom: 0;
    }

    .bold-copy {
      color: #111;
      font-size: 23px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    .yellow-trophy {
      margin-bottom: 0.25rem;

      img {
        height: 5rem;
        @media (min-width: 992px) {
          height: 3rem;
        }
      }
    }
  }

  .header-block-ring {
    background-color: transparent;
    border: 1.75rem solid #ebebeb;
    border-radius: 50%;
    height: 11rem;
    left: 0.75rem;
    position: absolute;
    width: 11rem;
  }

  .board-table-header {
    color: #8d8d8d;
    display: flex;
    font-size: 10px;

    .th {
      font-size: 15px;
      font-weight: 700;
      padding: 0.5rem 0;
    }

    .th-rank {
      text-align: center;
      width: 80px;
    }

    .th-user {
      flex: 1;
    }

    .th-score {
      text-align: center;
      width: 114px;
    }
  }

  .board-table-body {
    color: #464646;
    max-height: 512px;
    overflow-y: auto;

    @media (min-width: 1400px) {
      max-height: 350px;
    }

    .tr {
      align-items: center;
      border-top: 2px solid #f6f7fa;
      display: flex;
      padding: 4px 0;
      position: relative;

      &.tr-my-score {
        &::before {
          background-color: #7541be;
          border-radius: 25px;
          content: '';
          inset: 1px;
          position: absolute;
        }

        .col-rank,
        .user-name,
        .col-score {
          color: #fff;
          position: relative;
        }
      }
    }

    .col-rank {
      color: #cbcbcb;
      font-size: 15px;
      font-weight: 700;
      text-align: center;
      width: 80px;
    }

    .col-user {
      align-items: center;
      display: flex;
      flex: 1;

      img {
        border: 1px solid #545454 !important;
        border-radius: 50%;
      }

      span.user-name {
        color: #545454;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 16px;
        margin-left: 0.5rem;
      }
    }

    .col-score {
      color: #5d34a6;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      width: 114px;
    }
  }
}
