.caret-container {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;

  .caret-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
    position: absolute;
    right: 5px;
  }

  svg {
    left: -102px;
    position: absolute;
  }
}

.edit_event {
  .caret-container {
    .caret-down {
      right: 15px;
    }

    svg {
      left: -126px;
    }
  }
}
