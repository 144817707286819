.loading_container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.page-event-lobby {
  .row {
    .col:not(:first-child) {
      margin-left: 2rem;
    }
  }
}

@media (max-width: 400px) {
  .page-event-lobby {
    .row {
      .col:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}
