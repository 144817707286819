.page-event-lobby .activity-log {
  z-index: 9;

  & > p { // stylelint-disable-line
    font-size: 15px;
  }

  ul {
    font-size: 0.75rem;
    list-style: none;
    max-height: 384px;
    overflow: auto;
    padding-left: 0;

    li {
      align-items: center;
      border-radius: 20px;
      display: flex;
      padding: 0.25rem 0.5rem;

      .item-avatar {
        height: 1.5rem;
        position: relative;
        width: 1.5rem;

        img {
          border-radius: 50%;
        }
      }

      strong {
        font-weight: 600;
        margin-left: 0.5rem;
      }

      span.activity-type {
        margin-left: 0.25rem;
      }
    }

    li:nth-child(odd) {
      background-color: #e6e6e6;
    }
  }

  // Group Activity
  &.type-group {
    ul {
      max-height: 192px;

      @media (min-width: 1400px) {
        max-height: 224px;
      }
    }
  }
}
