.slick-dots {
  bottom: -45px !important;

  .slick-active {
    background: #207ac4;
  }

  li {
    align-items: center;
    background: #fff;
    border: 2.31474px solid #207ac4;
    border-radius: 50%;
    display: inline-flex !important;
    height: 18.52px !important;
    justify-content: center;
    width: 18.52px !important;

    button {
      border-radius: 50%;
      height: 100% !important;
      left: 0;
      margin: 0;
      position: absolute;
      top: 0;
      transition: transform 0.3s ease-in-out;
      width: 100% !important;
    }

    button::before {
      content: '' !important;
    }
  }
}

#game_carousel {
  .slick-slide {
    img {
      filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.45));
      transform: translate(120px, 0) scale(0.8);
    }
  }

  .slick-center + .slick-slide {
    img {
      filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.45));
      transform: translate(-120px, 0) scale(0.8);
    }
  }

  .slick-center.slick-current {
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.45));

    img {
      box-shadow: 15px 0 30px -20px rgb(0 0 0 / 40%), -15px 0 20px -20px rgb(0 0 0 / 40%);
      filter: unset;
      position: relative;
      transform: scale(1);
      z-index: 1;
    }
  }
}

@media (max-width: 1280px) {
  #game_carousel {
    .slick-slide {
      img {
        transform: translate(100px, 0) scale(0.8);
      }
    }

    .slick-center + .slick-slide {
      img {
        transform: translate(-100px, 0) scale(0.8);
      }
    }

    .slick-center.slick-current {
      img {
        transform: scale(1);
      }
    }
  }
}
