.screen-round-play {
  overflow-x: hidden;
  padding-top: 30px;
  position: relative;

  @media (max-width: 500px) {
    padding-top: 0;
  }

  .game-container {
    padding: 1rem 0;

    @media (min-width: 992px) {
      margin: 0 auto;
      width: 50%;

      &.is-full-width {
        width: 100%;
      }
    }
  }

  .overlay-container {
    background-color: #f5f5f5;
    border-radius: 10px 0 0 10px;
    bottom: 0;
    max-width: 530px;
    overflow-y: visible;
    padding: 1rem 1.875rem;
    position: fixed;
    right: 0;
    top: 80px; // top navbar
    transition: left 0.2s ease-in-out;
    width: 50%;
    z-index: 2;

    &.minimized {
      background-color: #fff;
      left: calc(100% - 3.75rem);

      & > *:not(.overlay-top-block) { /* stylelint-disable-line scss/selector-no-redundant-nesting-selector */
        display: none;
      }

      .btn-toggle {
        margin-left: -148px;

        img {
          transform: rotate(180deg);
        }

      }
    }
  }

  .overlay-top-block {
    margin-bottom: 1.25rem;

    // @TODO: check? https://codepen.io/TommiTikall/pen/xZwpGR
    .btn-toggle {
      align-items: center;
      background-color: #1d7bc3;
      border-radius: 52px;
      color: #fff;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      margin-left: -35px;
      overflow: hidden;
      padding-right: 25px;
      width: fit-content;

      .arrow-container {
        border-radius: 52px;
        height: 38px;
        margin-left: 5px;
        position: relative;
        width: 42px;
      }
    }

    .btn-toggle:hover {
      background: #0063b4;
    }
  }
}
