.event-info-block {
  background: #fff;
  border: 2px solid #ececec;
  border-radius: 10px;
  overflow: hidden;
  z-index: 9;

  .block-header {
    border-bottom: 2px solid #5d34a6;
    border-left: 13px solid #5d34a6;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    padding: 0 8px;

    @media (min-width: 992px) {
      border-left-width: 16px;
    }
  }

  .block-body {
    color: #292929;
    padding: 22px 20px;
  }

  .new-groups-text {
    color: #1b2a4a;
    font-size: 23px;
    font-weight: 800;
    line-height: 26px;
    @media (min-width: 992px) {
      font-size: 23px;
      line-height: 27px;
    }
  }

  .members-pane {
    align-items: center;
    background-color: #5d34a6;
    border-radius: 25px;
    display: flex;
    height: auto;
    padding: 4px 8px 4px 14px;
    width: fit-content;

    & > p { // stylelint-disable-line
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0;
    }

    .avatar-list {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      margin-left: 8px;
      max-width: 175px;
    }

    .avatar-list-item {
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      display: inline-flex;
      flex: 0 0 21px;
      height: 21px;
      justify-content: center;
      margin: 4px;
      width: 21px;

      img {
        border-radius: 50%;
      }
    }
  }

  .info-text {
    color: #737373;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 20px;

    &.in-group-text {
      color: #1b2a4a;
      font-weight: 600;
    }
  }

  .round-progress-section {
    margin-bottom: 1rem;
  }

  .progress-bar-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;

    .row-item {
      border: 1px solid #5d34a6;
      border-radius: 2px;
      flex: 0 0 70px;
      height: 6px;
      margin-bottom: 8px;
      margin-right: 8px;
      position: relative;

      @media (max-width: 300px) {
        flex: 0 0 56px;
      }
    }

    .row-item-full {
      background-color: #5d34a6;
    }

    .row-item-half {
      &::after {
        background-color: #5d34a6;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        width: 50%;
      }
    }

    .row-item-small {
      flex: 0 0 30px;
      margin-right: 4px;
    }
  }

  .timer-message {
    align-items: center;
    color: #000;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;

    @media (max-width: 300px) {
      display: block;
      font-size: 16px;
    }

    .text-live-red {
      color: #f00;
    }

    img {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}
